.about {
  width: 100%;
  height: 100%;
  padding: 0 20%;
  background-image: url("../images/line.svg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & * {
    margin-right: 0;
    margin-top: 0;
  }
}

.title {
  font-size: 2rem;
  margin-right: 6rem;
  text-align: center;
  margin-right: 0;
}

.subtitle {
  font-size: 1.5rem;
  margin-right: 6rem;
  text-align: center;
  margin-right: 0;
  margin-top: 1rem;
}
