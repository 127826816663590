.calculator {
  width: 100%;
  height: 100%;
  background-image: url("../images/line.svg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-out;
}
