html,
body,
#root {
  margin: 0;
  height: 100%;
}

.active {
  color: var(--accent) !important;
  font-weight: bold;
}
