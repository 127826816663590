@import "../css/vars.css";

/* Gotham */
@font-face {
  font-family: "Gotham";
  src: url(https://cdn.cpixl.com/fonts/gotham/GothamPro.woff2) format("woff2"),
    url(https://cdn.cpixl.com/fonts/gotham/GothamPro.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url(https://cdn.cpixl.com/fonts/gotham/GothamPro-Bold.woff2)
      format("woff2"),
    url(https://cdn.cpixl.com/fonts/gotham/GothamPro-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url(https://cdn.cpixl.com/fonts/gotham/GothamPro-Black.woff2)
      format("woff2"),
    url(https://cdn.cpixl.com/fonts/gotham/GothamPro-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

button {
  background-color: transparent;
  border: 2px solid var(--accent);
  display: inline-block;
  cursor: pointer;
  color: var(--text);
  padding: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Gotham, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  &:hover {
    background-color: var(--accent);
    color: var(--background);
  }
  &:active {
    position: relative;
    top: 0.2rem;
  }

  &:focus {
    outline: none;
  }
}

.App {
  padding: 0;
  height: 100%;
  font-family: Gotham, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);

  display: flex;
}

.aside {
  border-right: 0.2rem solid var(--accentColor);
  width: 22%;
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-out;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
}

.output {
  color: var(--accent);
  font-weight: 900;
  font-size: 4rem;
  max-width: 20rem;
}

.navlist {
  padding: 0;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  & li {
    list-style-type: none;
  }
}

.navlist__item {
  text-decoration: underline;
  color: var(--text);
  font-size: 1.5rem;
  &:hover {
    color: var(--accent);
  }
  &:active {
    position: relative;
    top: 0.2rem;
  }
}
