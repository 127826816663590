.slider__unit {
  color: var(--accent);
  font-weight: bold;
}

.slider__container {
  display: flex;
  gap: 2rem;
}

/* Styling range inputs */
input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  margin: 18px 0;
  width: 40rem;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--text);
    border-radius: 5px;
    border: 0px solid #000000;
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--text);
    border-radius: 5px;
    border: 0px solid #000000;
  }

  &::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: var(--accent);
    color: var(--accent);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
  }

  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: var(--accent);
    color: var(--accent);
  }

  &:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.title {
  font-size: 2rem;
  margin-right: 6rem;
  text-align: center;
}
.container {
  margin-top: 2rem;
}
