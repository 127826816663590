:root {
  --bgLight: #eee;
  --bgDark: #232323;
  --textLight: #7e7e7e;
  --textDark: #e6e6e6;
  --accentColor: #f3bf65;
  --orange1: #2b2b2b;
  --orange2: #ffefd4;
}

.light {
  --background: var(--bgLight);
  --text: var(--textLight);
  --accent: var(--accentColor);
}

.dark {
  --background: var(--bgDark);
  --text: var(--textDark);
  --accent: var(--accentColor);
}

.orange {
  --background: var(--accentColor);
  --text: var(--orange2);
  --accent: var(--orange1);
}
