.number__container {
  display: flex;
  gap: 2rem;
}
.number__input {
  padding: 0.5rem 5rem;
  //   display: block;
  margin: auto;
  margin-right: 11rem;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  background: none;
  border: none;
  border-bottom: 0.2rem solid var(--accent);
  color: var(--text);
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.title {
  font-size: 2rem;
  margin-right: 6rem;
  text-align: center;
}

.container {
  margin-top: 2rem;
}
